import { getRequest, postRequest } from './storeIndex'
import { SCMAPI } from '@/systemConfiguration/index'
// 支付规则-查询
export const tradePayList = (successCallback, failureCallback) => {
  getRequest('/trade/pay/rule/query', {}, res => successCallback(res), error => {
    if (failureCallback) failureCallback(error)
  }
  )
}
// 配送规则-查询
export const queryDeliveryRule = (successCallback, failureCallback) => {
  getRequest('/trade/delivery/rule/queryDeliveryRule', {}, res => successCallback(res), error => {
    if (failureCallback) failureCallback(error)
  }
  )
}
// 配送规则-编辑
export const deliveryRuleUpdate = (data, successCallback, failureCallback) => {
  postRequest('/trade/delivery/rule/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 支付规则-编辑与新增
export const addAndEditTrade = (data, successCallback, failureCallback) => {
  postRequest(data.type ? '/trade/pay/rule/save' : '/trade/pay/rule/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
export const getCollectionRules = (successCallback, failureCallback) => {
  getRequest(SCMAPI + 'v2/centralizedPurchase/rule/detail', {}, res => successCallback(res), error => {
    if (failureCallback) failureCallback(error)
  })
}
export const updateCollectionRules = (day, successCallback, failureCallback) => {
  getRequest(SCMAPI + `v2/centralizedPurchase/rule/update/${day}`, {}, res => successCallback(res), error => {
    if (failureCallback) failureCallback(error)
  })
}
